import React, { useState } from "react";
import { useAppDispatch } from '../hooks/reducer';
import { fetchAuth, fetchRegister } from '../store/slices/auth';
import { NavLink, useNavigate } from "react-router-dom";

const RegisterPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [email, setUserEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [password, setUserPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({
    username: false,
    // email: false,
    telephone: false,
    password: false,
    confirmPassword: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const registerHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let formErrors = {
      username: !username,
      email: !email,
      telephone: !telephone,
      password: !password,
      confirmPassword: !confirmPassword,
    };

    // Перевірка довжини username
    if (username.length < 3) {
      formErrors.username = true;
      setErrorMessage('Username must be at least 3 characters long.');
      setErrors(formErrors);
      return;
    }

    // // Перевірка формату електронної пошти
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRegex.test(email)) {
    //   formErrors.email = true;
    //   setErrorMessage('Please enter a valid email address.');
    //   setErrors(formErrors);
    //   return;
    // }

    setErrors(formErrors);

    if (formErrors.username || formErrors.email || formErrors.telephone || formErrors.password || formErrors.confirmPassword) {
      setErrorMessage('Please fill in all fields.');
      return;
    }

    // Перевірка довжини пароля і наявності великої літери
    const passwordRegex = /^(?=.*[A-Z]).{8,}$/;
    if (!passwordRegex.test(password)) {
      setErrorMessage('Password must be at least 8 characters long and contain at least one uppercase letter.');
      setErrors({ ...formErrors, password: true });
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      setErrors({ ...formErrors, password: true, confirmPassword: true });
      return;
    }

    setErrorMessage('');
    setErrors({
      username: false,
      // email: false,
      telephone: false,
      password: false,
      confirmPassword: false,
    });

    const params = { username, email, telephone, password };
    console.log(params);
    dispatch(fetchRegister(params));
  };

  return (
    <div className="relative min-h-screen bg-gray-100 flex flex-col">
      <div
        className="absolute top-8 left-8 flex items-center cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6 text-gray-800 hover:text-gray-600"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
        <span className="ml-2 text-gray-800 hover:text-gray-600">Back</span>
      </div>

      <div className="flex flex-grow items-center justify-center">
        <div className="bg-white shadow-none p-8 rounded-lg max-w-screen-lg sm:w-96 w-80">
          <h2 className="text-4xl font-semibold text-gray-800 mb-2">Sign Up</h2>
          <p className="text-gray-500 font-normal mb-8">
            Nice to meet you! Enter your details to register.
          </p>
          <form className="space-y-6" onSubmit={registerHandler}>
            <div className="flex flex-col gap-4">
              <label className="text-lg font-medium text-gray-800 -mb-3">
                Username
              </label>
              <input
                type="text"
                placeholder="Enter your username"
                className={`border rounded-lg p-2 focus:outline-none focus:border-gray-900 ${
                  errors.username ? 'border-red-500' : 'border-gray-300'
                }`}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />

              {/* <label className="text-lg font-medium text-gray-800 -mb-3">
                Your Email
              </label>
              <input
                type="email"
                placeholder="name@mail.com"
                className={`border rounded-lg p-2 focus:outline-none focus:border-gray-900 ${
                  errors.email ? 'border-red-500' : 'border-gray-300'
                }`}
                value={email}
                onChange={(e) => setUserEmail(e.target.value)}
              /> */}

              <label className="text-lg font-medium text-gray-800 -mb-3">
                Your Telephone Number
              </label>
              <input
                type="text"
                placeholder="+380 (xx) xxx xx xx"
                className={`border rounded-lg p-2 focus:outline-none focus:border-gray-900 ${
                  errors.telephone ? 'border-red-500' : 'border-gray-300'
                }`}
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
              />

              <label className="text-lg font-medium text-gray-800 -mb-3">
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="********"
                  className={`border rounded-lg p-2 w-full focus:outline-none focus:border-gray-900 ${
                    errors.password ? 'border-red-500' : 'border-gray-300'
                  }`}
                  value={password}
                  onChange={(e) => setUserPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M17.94 17.94A10 10 0 0 1 2 12S5.5 7 12 7s10 5 10 5a9.89 9.89 0 0 1-4.35 5.56"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M1 12s4-5 11-5 11 5 11 5-4 5-11 5-11-5-11-5z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                  )}
                </button>
              </div>

              <label className="text-lg font-medium text-gray-800 -mb-3">
                Confirm Password
              </label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="********"
                  className={`border rounded-lg p-2 w-full focus:outline-none focus:border-gray-900 ${
                    errors.confirmPassword ? 'border-red-500' : 'border-gray-300'
                  }`}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M17.94 17.94A10 10 0 0 1 2 12S5.5 7 12 7s10 5 10 5a9.89 9.89 0 0 1-4.35 5.56"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M1 12s4-5 11-5 11 5 11 5-4 5-11 5-11-5-11-5z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                  )}
                </button>
              </div>
            </div>

            {errorMessage && (
              <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
            )}

            <div className="flex items-start -ml-2.5">
              <input
                type="checkbox"
                className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              />
              <label className="ml-2 text-sm text-gray-600">
                I agree to the
                <a
                  href="#"
                  className="text-gray-900 font-medium hover:underline ml-1"
                >
                  Terms and Conditions
                </a>
              </label>
            </div>

            <button
              type="submit"
              className="w-full bg-blue-600 text-white font-medium py-2 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none"
            >
              Sign Up
            </button>

            <p className="text-center text-gray-500 mt-4">
              Already have an account?{" "}
              <NavLink to="/login">
                <span className="text-gray-900 font-medium hover:underline">
                  Sign In
                </span>
              </NavLink>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
