import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../images/user/user-02.png';

interface Course {
  id: number;
  companyLogo: string;
  companyName: string;
  courseTitle: string;
  description: string;
  profession: string;
}

const courses: Course[] = [
    {
      id: 1,
      companyLogo: logo,
      companyName: 'TechCorp',
      courseTitle: 'React для початківців',
      description: 'Вивчіть основи React і почніть створювати сучасні веб-додатки.',
      profession: 'Frontend Developer',
    },
    {
      id: 2,
      companyLogo: logo,
      companyName: 'DevStudio',
      courseTitle: 'Глибоке вивчення JavaScript',
      description: 'Отримайте глибокі знання JavaScript для просунутих проектів.',
      profession: 'Fullstack Developer',
    },
    {
      id: 3,
      companyLogo: logo,
      companyName: 'CodeMasters',
      courseTitle: 'Основи Python',
      description: 'Досліджуйте світ програмування з Python.',
      profession: 'Backend Developer',
    },
    {
      id: 4,
      companyLogo: logo,
      companyName: 'WebSolutions',
      courseTitle: 'Веб-дизайн для початківців',
      description: 'Дізнайтеся, як створювати привабливі веб-дизайни.',
      profession: 'UI/UX Designer',
    },
    {
      id: 5,
      companyLogo: logo,
      companyName: 'AI Innovations',
      courseTitle: 'Машинне навчання 101',
      description: 'Вивчіть основи машинного навчання та створіть свої перші моделі.',
      profession: 'Data Scientist',
    },
    {
      id: 6,
      companyLogo: logo,
      companyName: 'SecureTech',
      courseTitle: 'Основи кібербезпеки',
      description: 'Захистіть свої дані від кібератак і загроз.',
      profession: 'Cybersecurity Specialist',
    },
    {
      id: 7,
      companyLogo: logo,
      companyName: 'DevOps Hub',
      courseTitle: 'DevOps: від основ до практики',
      description: 'Вивчіть методології DevOps і автоматизацію процесів.',
      profession: 'DevOps Engineer',
    },
    {
      id: 8,
      companyLogo: logo,
      companyName: 'Cloud Masters',
      courseTitle: 'Хмарні обчислення з AWS',
      description: 'Навчіться створювати та керувати хмарними сервісами.',
      profession: 'Cloud Architect',
    },
    {
      id: 9,
      companyLogo: logo,
      companyName: 'MobileSoft',
      courseTitle: 'Розробка мобільних додатків з Flutter',
      description: 'Створюйте багатоплатформні мобільні додатки з Flutter.',
      profession: 'Mobile Developer',
    },
    {
      id: 10,
      companyLogo: logo,
      companyName: 'GameDev Studio',
      courseTitle: 'Основи розробки ігор',
      description: 'Дізнайтеся, як створювати ігри на Unity.',
      profession: 'Game Developer',
    },
    {
      id: 11,
      companyLogo: logo,
      companyName: 'AnalyticsPro',
      courseTitle: 'Аналіз даних з Power BI',
      description: 'Візуалізуйте та аналізуйте дані за допомогою Power BI.',
      profession: 'Data Analyst',
    },
];

const CourseList: React.FC = () => {
    return (
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        {/* <h1 className="text-3xl font-bold text-center my-8">Курси</h1> */}
        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {courses.map((course) => (
            <div
              key={course.id}
              className="flex flex-col bg-white shadow-md rounded-lg overflow-hidden"
            >
              <div className="p-4 flex items-center">
                <img
                  className="h-20 w-20 object-contain mr-4"
                  src={course.companyLogo}
                  alt={course.companyName}
                />
                <h3 className="text-lg font-semibold">{course.companyName}</h3>
              </div>
              <div className="p-4 flex flex-col justify-between flex-grow">
                <h2 className="text-xl font-bold mb-2">{course.courseTitle}</h2>
                <p className="text-gray-600 mb-4">{course.description}</p>
                <span className="text-sm font-semibold text-orange-500">
                  Професія: {course.profession}
                </span>
                <NavLink
                  to={`/course/${course.id}`}
                  className="mt-4 inline-block text-center bg-orange-500 text-orange-100 px-4 py-2 rounded hover:bg-orange-600 hover:text-white"
                >
                  Деталі курсу
                </NavLink>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default CourseList;
