import LoginPage from "../pages/LoginPage";
import RegisterPage from "../pages/RegisterPage";
// import Home from "../pages/Home";
// import StartPage from '../pages/StartPage'
// import AboutPage from "../pages/AboutPage";
// import MyProjPage from "../pages/MyProjPage";
// import NetWorkPage from "../pages/NetWorkPage";
// import DeepNetPage from "../pages/DeepNetPage";
import LandingPage from "../pages/LandingPage";

import Settings from "../pages/Settings"
import TestPage from "../pages/TestPage"
import VacancyListPage from "../pages/VacancyListPage"
import CourseList from "../pages/CourseList"
import CourseDetail from "../pages/CourseDetail"
import CreateCoursePage from "../pages/CreateCoursePage"


import HomePage from "../pages/LandingSet/HomePage"
import CourseGridPage from "../pages/LandingSet/CourseGridPage"
import CourseDetailsPage from "../pages/LandingSet/CourseDetailsPage"
import EventDetailsPage from "../pages/LandingSet/EventDetailsPage"
import LoginNewPage from "../pages/LandingSet/LoginNewPage"
import RegistrationPage from "../pages/LandingSet/RegistrationPage"

import CourseGrafPage from "../pages/LandingSet/courses/CourseGrafPage"
import CourseBugalterPage from "../pages/LandingSet/courses/CourseBugalterPage"

import CourseCouchPage from "../pages/LandingSet/courses/CourseCouchPage"
import CourseCallPage from "../pages/LandingSet/courses/CourseCallPage"
import CourseSellPage from "../pages/LandingSet/courses/CourseSellPage"
import CourseTargetPage from "../pages/LandingSet/courses/CourseTargetPage"




export const publicRoutes = [
    {path: '/login', element: <LoginPage/>},
    {path: '/loginn', element: <LoginNewPage/>},
    {path: '/register', element: <RegisterPage/>},
    {path: '/registerr', element: <RegistrationPage/>},
    {path: '/start', element: <HomePage/>},
    // { path: '/event-details', element: <EventDetailsPage/> },
]

export const privateRoutes = [
    {path: '/home', element: <Settings/>},
    {path: '/settings', element: <Settings/>},
    {path: '/vacancylist', element: <VacancyListPage/>},
    {path: '/courselist', element: <CourseList/>},
    { path: '/course/:id', element: <CourseDetail /> },
    { path: '/courseGrafPage', element: <CourseGrafPage /> },

    // { path: '/courseCreate', element: <CreateCoursePage/> },
    {path: '/test', element: <TestPage/>},
    { path: '/course-grid-page', element: <CourseGridPage/> },
    
    { path: '/course-details:1', element: <CourseGrafPage/> },
    { path: '/course-details:2', element: <CourseBugalterPage/> },
    // { path: '/course-details:3', element: <CourseCouchPage/> },
    { path: '/course-details:4', element: <CourseCallPage/> },
    { path: '/course-details:5', element: <CourseSellPage/> },
    { path: '/course-details:6', element: <CourseTargetPage/> },
    
    { path: '/course-details', element: <CourseDetailsPage/> },

    
]




// export const privateRoutes = [
//     {path: '/home', element: <Home/>},
//     {path: '/about', element: <AboutPage/>},
//     {path: '/myproj', element: <MyProjPage/>},
//     {path: '/network', element: <NetWorkPage/>},
//     {path: '/deepnet', element: <DeepNetPage/>},
// ]