import React from 'react';
// import logo from '../../../public/assets/'

import photosnapLogo from '../../assets/images/photosnap.svg';
import manageLogo from '../../assets/images/manage.svg';
import accountLogo from '../../assets/images/account.svg';
import myhomeLogo from '../../assets/images/myhome.svg';
import loopStudiosLogo from '../../assets/images/loop-studios.svg';
import faceitLogo from '../../assets/images/faceit.svg';
import shortlyLogo from '../../assets/images/shortly.svg';
import insureLogo from '../../assets/images/insure.svg';
import eyecamLogo from '../../assets/images/eyecam-co.svg';
import theAirFilterCompanyLogo from '../../assets/images/the-air-filter-company.svg';


const logos: { [key: string]: string } = {
    'images/photosnap.svg': photosnapLogo,
    'images/manage.svg': manageLogo,
    'images/account.svg': accountLogo,
    'images/myhome.svg': myhomeLogo,
    'images/loop-studios.svg': loopStudiosLogo,
    'images/faceit.svg': faceitLogo,
    'images/shortly.svg': shortlyLogo,
    'images/insure.svg': insureLogo,
    'images/eyecam-co.svg': eyecamLogo,
    'images/the-air-filter-company.svg': theAirFilterCompanyLogo,
  };
  

interface JobProps {
  job: {
    company: string;
    logo: string;
    isNew: boolean;
    featured: boolean;
    position: string;
    role: string;
    level: string;
    postedAt: string;
    contract: string;
    location: string;
    languages: string[];
    tools: string[];
  };
  handleTagClick: (tag: string) => void;
}

const JobBoard: React.FC<JobProps> = ({
  job: {
    company,
    logo,
    isNew,
    featured,
    position,
    role,
    level,
    postedAt,
    contract,
    location,
    languages,
    tools,
  },
  handleTagClick,
}) => {

    const imageSrc = logos[logo];


    const tags = [role, level];
    if (tools) {
        tags.push(...tools);
    }
    if (languages) {
        tags.push(...languages);
    }
    return (
    // <div
    //   className={`flex flex-col bg-white shadow-md mx-1 my-8 p-6 rounded sm:flex-row sm:my-8 ${
    //     featured && 'border-l-4 border-orange-600 border-solid'
    //   }`}
    // >
    <div
      className={`flex flex-col bg-white shadow-md mx-1 my-8 p-6 rounded sm:flex-row sm:my-8 ${
        'border-l-4 border-orange-600 border-solid'
      }`}
    >
      <div>
        <img
          className="-mt-16 mb-2 h-15 w-15 sm:mt-0 sm:my-0"
          src={imageSrc}
          alt={company}
        />
      </div>
      <div className="flex flex-col justify-between ml-4">
        <h3 className="font-bold text-orange-500">
          {company}{' '}
          {isNew && (
            <span className="text-sm bg-orange-500 text-orange-100 font-bold ml-2 py-1 px-2 rounded-full uppercase">
              New
            </span>
          )}
        </h3>
        <h2 className="font-bold text-xl">{position}</h2>
        <p className="text-gray-600">
          {postedAt} | {contract} | {location}
        </p>
      </div>
      <div className="flex flex-wrap items-center mt-4 mx-4 pt-2 border-t border-gray-500 sm:ml-auto sm:border-0 sm:mt-0 sm:pt-0">
        {tags.map((tag) => (
          <span
            key={tag}
            onClick={() => handleTagClick(tag)}
            className="cursor-pointer text-orange-500 bg-orange-100 font-bold mb-2 mr-4 p-2 rounded sm:mb-0"
          >
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};

export default JobBoard;
