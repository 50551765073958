import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAuthData } from "../../types/user";
import axios from "axios";

// Базовий URL вашого API
// export const baseUrl = 'https://78ae-176-98-23-183.ngrok-free.app/';
export const baseUrl = process.env.REACT_APP_API_URL || 'https://yellow-hub-35b14f48a8c1.herokuapp.com/';

// Асинхронна функція для авторизації
export const fetchAuth = createAsyncThunk('auth/login', async (params: any) => {
    try {
        const response = await axios.post<IAuthData>(baseUrl + `auth/login`, params);

        // Збережіть токен у localStorage
        localStorage.setItem('token', response.data.token);
        // localStorage.setItem('userId', response.data.user._id);

        // Встановіть токен у заголовки для майбутніх запитів
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

        return response.data;
    } catch (e) {
        console.log(e);
    }
});

// Асинхронна функція для реєстрації
export const fetchRegister = createAsyncThunk('auth/fetchRegister', async (params: any) => {
    try {
        const response = await axios.post<IAuthData>(baseUrl + `auth/register`, params);

        // Збережіть токен у localStorage
        localStorage.setItem('token', response.data.token);
        // localStorage.setItem('userId', response.data.user._id);

        // Встановіть токен у заголовки для майбутніх запитів
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

        return response.data;
    } catch (e) {
        console.log(e);
    }
});


// Асинхронна функція для отримання даних користувача
// export const fetchUserData = createAsyncThunk('auth/fetchUserData', async () => {
//     try {
//         const token = localStorage.getItem('token');

//         const id = localStorage.getItem('userId');

//         if (!token) {
//             throw new Error('Token not found');
//         }

//         // Додаємо токен у заголовки
//         const response = await axios.get(baseUrl + 'auth/getUser', {
//             headers: {
//                 Authorization: `Bearer ${id}`,
//             },
//         });

//         return response.data;
//     } catch (e) {
//         console.error('Error fetching user data:', e);
//     }
// });

export const fetchUserData = createAsyncThunk('auth/fetchUserData', async () => {
    try {
        const token = localStorage.getItem('token');

        if (!token) {
            throw new Error('Token not found');
        }

        // Відправка токена у форматі JSON через POST
        const response = await axios.post(baseUrl + 'auth/getUser', { token }, { 
            headers: {
                'Content-Type': 'application/json' // Вказуємо, що відправляємо JSON
            },
        });

        // console.log('Sending POST request to:', baseUrl + 'auth/getUser');
        // console.log('Token being used:', token);

        return response.data;
    } catch (e) {
        console.error('Error fetching user data:', e);
    }
});

// export const fetchUserData = createAsyncThunk('auth/fetchUserData', async () => {
//     try {
//         // const id = localStorage.getItem('userId');
//         const token = localStorage.getItem('token');

//         if (!token) {
//             throw new Error('Token not found');
//         }

//         // if (!id) {
//         //     throw new Error('User ID not found');
//         // }

//         // console.log(id)
//         // console.log(token)

//         // Додаємо id у заголовки
//         const response = await axios.get(baseUrl + 'auth/getUser', {
//             headers: {
//                 // 'User-ID': id,
//                 Authorization: `Bearer ${token}` // Використовуємо заголовок 'User-ID'
//             },
//         });

//         console.log('1111')
//         console.log('Sending request to:', baseUrl + 'auth/getUser');
//         console.log('Token being used:', token);
//         // console.log(response.data)

//         return response.data;
//     } catch (e) {
//         console.error('Error fetching user data:', e);
//     }
// });


// Функція виходу
export const logout = () => {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
};

interface IUserState {
    data: null | IAuthData;
    status: string;
    isAuthenticated: boolean; // Додаємо поле для перевірки авторизації
}

const initialState: IUserState = {
    data: null,
    status: 'loading',
    isAuthenticated: false // Початково користувач не авторизований
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetData: (state) => {
            state.data = null;
            state.isAuthenticated = false;
        },
        setAuthenticated: (state) => {
            state.isAuthenticated = true; // Встановлюємо авторизацію в true
        },
        logout: (state) => {
            // Скидаємо дані стану при логауті
            state.data = null;
            state.isAuthenticated = false;
        },
    },
    extraReducers: {
        [fetchAuth.pending.type]: (state) => {
            state.status = 'loading';
            state.data = null;
        },
        [fetchAuth.fulfilled.type]: (state, action: PayloadAction<IAuthData>) => {
            state.status = 'loaded';
            state.data = action.payload;
            state.isAuthenticated = true; // Встановлюємо авторизацію в true
        },
        [fetchAuth.rejected.type]: (state) => {
            state.status = 'error';
            state.data = null;
            state.isAuthenticated = false;
        },
        [fetchRegister.pending.type]: (state) => {
            state.status = 'loading';
            state.data = null;
        },
        [fetchRegister.fulfilled.type]: (state, action: PayloadAction<IAuthData>) => {
            state.status = 'loaded';
            state.data = action.payload;
            state.isAuthenticated = true; // Встановлюємо авторизацію в true
        },
        [fetchRegister.rejected.type]: (state) => {
            state.status = 'error';
            state.data = null;
            state.isAuthenticated = false;
        },
        [fetchUserData.pending.type]: (state) => {
            state.status = 'loading';
        },
        [fetchUserData.fulfilled.type]: (state, action: PayloadAction<IAuthData>) => {
            state.status = 'loaded';
            // console.log(action.payload)
            state.data = action.payload;
            state.isAuthenticated = true; // Встановлюємо авторизацію в true
        },
        [fetchUserData.rejected.type]: (state) => {
            state.status = 'error';
            state.data = null;
            state.isAuthenticated = false;
        },
    }
});

export default authSlice.reducer;
export const { resetData, setAuthenticated } = authSlice.actions;
