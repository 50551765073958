import React from 'react';
import { useParams } from 'react-router-dom';
import logo from '../images/user/user-02.png';

interface Course {
  id: number;
  companyLogo: string;
  companyName: string;
  courseTitle: string;
  description: string;
  profession: string;
}

const courses: Course[] = [
    {
      id: 1,
      companyLogo: logo,
      companyName: 'TechCorp',
      courseTitle: 'React для початківців',
      description: 'Вивчіть основи React і почніть створювати сучасні веб-додатки.',
      profession: 'Frontend Developer',
    },
    {
      id: 2,
      companyLogo: logo,
      companyName: 'DevStudio',
      courseTitle: 'Глибоке вивчення JavaScript',
      description: 'Отримайте глибокі знання JavaScript для просунутих проектів.',
      profession: 'Fullstack Developer',
    },
    {
      id: 3,
      companyLogo: logo,
      companyName: 'CodeMasters',
      courseTitle: 'Основи Python',
      description: 'Досліджуйте світ програмування з Python.',
      profession: 'Backend Developer',
    },
    {
      id: 4,
      companyLogo: logo,
      companyName: 'WebSolutions',
      courseTitle: 'Веб-дизайн для початківців',
      description: 'Дізнайтеся, як створювати привабливі веб-дизайни.',
      profession: 'UI/UX Designer',
    },
    {
      id: 5,
      companyLogo: logo,
      companyName: 'AI Innovations',
      courseTitle: 'Машинне навчання 101',
      description: 'Вивчіть основи машинного навчання та створіть свої перші моделі.',
      profession: 'Data Scientist',
    },
    {
      id: 6,
      companyLogo: logo,
      companyName: 'SecureTech',
      courseTitle: 'Основи кібербезпеки',
      description: 'Захистіть свої дані від кібератак і загроз.',
      profession: 'Cybersecurity Specialist',
    },
    {
      id: 7,
      companyLogo: logo,
      companyName: 'DevOps Hub',
      courseTitle: 'DevOps: від основ до практики',
      description: 'Вивчіть методології DevOps і автоматизацію процесів.',
      profession: 'DevOps Engineer',
    },
    {
      id: 8,
      companyLogo: logo,
      companyName: 'Cloud Masters',
      courseTitle: 'Хмарні обчислення з AWS',
      description: 'Навчіться створювати та керувати хмарними сервісами.',
      profession: 'Cloud Architect',
    },
    {
      id: 9,
      companyLogo: logo,
      companyName: 'MobileSoft',
      courseTitle: 'Розробка мобільних додатків з Flutter',
      description: 'Створюйте багатоплатформні мобільні додатки з Flutter.',
      profession: 'Mobile Developer',
    },
    {
      id: 10,
      companyLogo: logo,
      companyName: 'GameDev Studio',
      courseTitle: 'Основи розробки ігор',
      description: 'Дізнайтеся, як створювати ігри на Unity.',
      profession: 'Game Developer',
    },
    {
      id: 11,
      companyLogo: logo,
      companyName: 'AnalyticsPro',
      courseTitle: 'Аналіз даних з Power BI',
      description: 'Візуалізуйте та аналізуйте дані за допомогою Power BI.',
      profession: 'Data Analyst',
    },
  ];

const CourseDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const course = courses.find((course) => course.id === Number(id));

  if (!course) {
    return <p>Курс не знайдено</p>;
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col bg-white shadow-md rounded-lg overflow-hidden p-6">
        <div className="flex items-center mb-4">
          <img
            className="h-16 w-16 object-contain mr-4"
            src={course.companyLogo}
            alt={course.companyName}
          />
          <h1 className="text-2xl font-bold">{course.courseTitle}</h1>
        </div>
        <h3 className="text-lg font-semibold mb-2">{course.companyName}</h3>
        <p className="text-gray-600 mb-4">{course.description}</p>
        <span className="text-sm font-semibold text-orange-500">
          Професія: {course.profession}
        </span>
      </div>
    </div>
  );
};

export default CourseDetail;
