import React, { useEffect, useState } from 'react';

import '../css/style.css';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';
import DefaultLayout from '../layout/DefaultLayout';
import Settings from './Settings';
import PageTitle from '../components/Home/PageTitle';
import JobBoard from '../components/Home/JobBoard';

import data from '../assets/data.json';

// Інтерфейс для типізації даних про вакансії
interface Job {
  id: number;
  company: string;
  logo: string;
  isNew: boolean;
  featured: boolean;
  position: string;
  role: string;
  level: string;
  postedAt: string;
  contract: string;
  location: string;
  languages: string[];
  tools: string[];
}

const VacancyListPage: React.FC = () => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [filters, setFilters] = useState<string[]>([]);

  useEffect(() => {
    setJobs(data);
  }, []);

  const filterFunc = ({ role, level, tools, languages }: Job) => {
    if (filters.length === 0) {
      return true;
    }
    const tags = [role, level];
    if (tools) {
      tags.push(...tools);
    }
    if (languages) {
      tags.push(...languages);
    }
    return tags.some((tag) => filters.includes(tag));
  };

  const handleTagClick = (tag: string) => {
    if (filters.includes(tag)) return;
    setFilters([...filters, tag]);
  };

  const handleFilterClick = (passedFilter: string) => {
    setFilters(filters.filter((f) => f !== passedFilter));
  };

  const clearFilters = () => {
    setFilters([]);
  };

  const filteredJobs = jobs.filter(filterFunc);

  return (
    <div className="App">
      
      <div className="container m-auto">
        {filters.length > 0 && (
          <div
            className={`flex flex-wrap bg-white shadow-md mx-10 -my-20 mb-16 m-4 p-6 z-10 relative rounded`}
          >
            {filters.map((filter) => (
              <span
                key={filter}
                onClick={() => handleFilterClick(filter)}
                className="cursor-pointer text-orange-500 bg-orange-100 font-bold mb-2 mr-4 p-2 rounded sm:mb-0"
              >
                {filter}
                <span className="text-sm bg-orange-500 text-orange-100 font-bold m-2 py-1 px-2 rounded-full uppercase">
                  &#10006;
                </span>
              </span>
            ))}
            <button
              onClick={clearFilters}
              className="text-sm bg-orange-700 text-orange-100 font-bold ml-auto m-2 py-1 px-2 rounded-full uppercase"
            >
              Clear
            </button>
          </div>
        )}
        {jobs.length === 0 ? (
          <p>Jobs are fetching...</p>
        ) : (
          filteredJobs.map((job) => (
            <JobBoard job={job} key={job.id} handleTagClick={handleTagClick} />
          ))
        )}
      </div>
    </div>
  );
};

export default VacancyListPage;
