import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { publicRoutes, privateRoutes } from './router/index';
import { useAppSelector, useAppDispatch } from './hooks/reducer'; // Імпортуйте ваш хук dispatch
import Header from './components/Home/Header';
import Sidebar from './components/Home/Sidebar';
import axios from 'axios';
import { fetchUserData, setAuthenticated } from './store/slices/auth'; // Імпортуйте екшн для встановлення авторизації

function App() {
    const dispatch = useAppDispatch(); // Ініціалізація dispatch
    const isAuth = useAppSelector(state => state.authReducer.data);

    

    const [sidebarOpen, setSidebarOpen] = useState(false);

    // Використовуйте useEffect для відновлення токена при завантаженні додатку
    useEffect(() => {
        // Перевірка наявності токена в localStorage
        const token = localStorage.getItem('token');
        console.log('Token on page load:', token); // Додайте лог для перевірки

        if (token) {
            // Встановіть токен у заголовки для майбутніх запитів
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

            // Оновіть стан авторизації в Redux
            // dispatch(setAuthenticated());

            dispatch(fetchUserData());
        }
    }, [dispatch]);

    return (
        <div className={isAuth ? 'App app_private' : 'App app_public'}>
            <BrowserRouter>
                {!isAuth ? (
                    <Routes>
                        {publicRoutes.map(route => (
                            <Route key={route.path} path={route.path} element={route.element} />
                        ))}
                        <Route path='/' element={<Navigate to='/start' />} />
                        <Route path='*' element={<Navigate to='/start' />} />
                    </Routes>
                ) : (
                    <div className="flex h-screen">
                        {/* Sidebar */}
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                        <div className="flex-1 flex flex-col">
                            {/* Header */}
                            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                            {/* Content */}
                            <div className="flex-1 p-6 overflow-y-auto">
                                <Routes>
                                    {privateRoutes.map(route => (
                                        <Route key={route.path} path={route.path} element={route.element} />
                                    ))}
                                    {/* {publicRoutes.map(route => (
                                        <Route key={route.path} path={route.path} element={route.element} />
                                    ))} */}
                                    <Route path="/" element={<Navigate to="/course-grid-page" />} />
                                    <Route path="*" element={<Navigate to="/course-grid-page" />} />
                                </Routes>
                            </div>
                        </div>
                    </div>
                )}
            </BrowserRouter>
        </div>
    );
}

export default App;
